import React from "react";
import * as Icons from "react-icons/fa";
import styled from "styled-components";

interface IconTitleProps {
	iconName?: string;
	iconComponent?: React.ElementType; // Add iconComponent prop
	title: string;
	color?: string;
	size?: number;
	textSize?: number;
}

const IconTitle: React.FC<IconTitleProps> = ({
	iconName,
	iconComponent: IconComponent,
	title,
	color = "var(--color-text)",
	size = 20,
	textSize,
}) => {
	// Default to iconName if iconComponent is not provided
	const Icon = IconComponent || (iconName && Icons[iconName as keyof typeof Icons]);
	const finalTextSize = textSize || size; // Use icon size if text size is not provided

	return (
		<IconTitleContainer color={color} textSize={finalTextSize} iconSize={size}>
			{Icon && <Icon size={size} fill={color} />} {/* Use Icon */}
			<span>{title}</span>
		</IconTitleContainer>
	);
};

export default IconTitle;

const IconTitleContainer = styled.div<{ color: string; textSize: number; iconSize: number }>`
	display: flex;
	align-items: center;
	span {
		color: ${({ color }) => color};
		margin-left: ${({ iconSize }) => iconSize * 0.7}px; // Dynamic margin
		font-size: ${({ textSize }) => textSize}px;
	}
`;
