import { AboutMeInterface } from "../Interfaces/AboutMeInterface";

export const aboutMeData: AboutMeInterface = {
	fullname: "Fayçal BEN LARBI DELAI",
	profession: "FULL-STACK ENGINEER",
	aboutme:
		"I am a Full-Stack Engineer skilled in building dynamic web applications using technologies like ReactJS, Typescript, Angular, NodeJS, and Postgres. With a focus on delivering efficient and user-friendly solutions, I bring a blend of technical expertise and design insight to every project.",
	socialmedia: [],
	contact: {
		address: "Marrakech, Morocco",
		phone: ["+212 615 886 330"],
		mail: ["info@fayonation.com"],
		linkedIn: {
			title: "@fayonation",
			link: "https://www.linkedin.com/in/fayonation-digital-agency/",
		},
	},
};
