import styled from "styled-components";
import { aboutMeData } from "../data/aboutMeData";
import profilPic from "../assets/profile_picture.webp";
import ContactRows from "../components/ContactRows";
import { AboutIntro, FullName, Profession, Profile } from "../components/Misc";

const AboutMeComponent: React.FC = () => {
	return (
		<AboutContainer className="desktop-only">
			<Profile alt="profilePic" src={profilPic} />
			<div>
				<FullName>{aboutMeData.fullname}</FullName>
				<Profession>{aboutMeData.profession}</Profession>
			</div>
			<hr />
			<AboutIntro>{aboutMeData.aboutme}</AboutIntro>
			<hr />
			<ContactRows />
		</AboutContainer>
	);
};
export default AboutMeComponent;

const AboutContainer = styled.div`
	position: fixed;
	width: 30vw;
	top: 0;
	left: 0;
	bottom: 0;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-background);
	padding: var(--spacing-medium) 0;
	padding: 1rem;
	flex-direction: column;
	box-sizing: border-box;
	gap: 1rem;
`;
