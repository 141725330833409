import React from "react";
import styled from "styled-components";
import { aboutMeData } from "../data/aboutMeData";
import IconTitle from "./IconTitle";

const ContactRows: React.FC = () => {
	return (
		<ContactRowsContainer>
			<div className="address">
				<IconTitle iconName="FaMapMarkerAlt" title={aboutMeData.contact.address} color="var(--color-white)" />
			</div>
			<a href={aboutMeData.contact.linkedIn.link} target="_blank" rel="noopener noreferrer">
				<IconTitle iconName="FaLinkedin" title={aboutMeData.contact.linkedIn.title} color="var(--color-white)" />
			</a>
			{aboutMeData.contact.phone.map((phone, index) => (
				<a key={index} href={`tel:${phone}`}>
					<IconTitle iconName="FaPhone" title={phone} color="var(--color-white)" />
				</a>
			))}
			{aboutMeData.contact.mail.map((email, index) => (
				<a key={index} href={`mailto:${email}`}>
					<IconTitle iconName="FaEnvelope" title={email} color="var(--color-white)" />
				</a>
			))}
		</ContactRowsContainer>
	);
};

export default ContactRows;

const ContactRowsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
`;
