import React from "react";
import styled from "styled-components";
import "./App.css";
import AboutMeComponent from "./sections/AboutMeComponent";
import PortfolioComponent from "./sections/PortfolioComponent";
import ProSkillsComponent from "./sections/ProSkillsComponent";
import ExperienceComponent from "./sections/ExperienceComponent";
import MobileHeader from "./components/MobileHeader";

const App: React.FC = () => {
	return (
		<AppContainer>
			<MobileHeader className="only-mobile" />
			<AboutMeComponent />
			<ContentFragment>
				<ExperienceComponent />
				<ProSkillsComponent />
				<PortfolioComponent />
			</ContentFragment>
		</AppContainer>
	);
};

const AppContainer = styled.div`
	flex-direction: row;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const ContentFragment = styled.div`
	width: 70vw;
	margin-left: 30vw;
	padding: 1rem;
	box-sizing: border-box;
	gap: 1rem;
	display: flex;
	flex-direction: column;

	@media (max-width: 768px) {
		width: 100vw;
		margin-left: 0;
	}
`;

export default App;
