import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import profilPic from "../assets/profile_picture.webp";
import { aboutMeData } from "../data/aboutMeData";
import { AboutIntro, FullName, Profession, Profile } from "./Misc";
import ContactRows from "./ContactRows";

const padding = 1;
const headerHeight = 100;
interface MobileHeaderProps {
	className?: string;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ className }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	useEffect(() => {
		if (isDrawerOpen) {
			// Scroll to top before preventing scroll
			window.scrollTo(0, 0);
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "visible";
		}

		// Cleanup function to reset overflow when component unmounts
		return () => {
			document.body.style.overflow = "visible";
		};
	}, [isDrawerOpen]);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
		console.log("fayfay Drawer toggled:", !isDrawerOpen);
	};

	return (
		<MobileHeaderContainer className="only-mobile">
			<div className="drawer-header" style={{ height: `${headerHeight}px` }}>
				<Profile alt="profilePic" src={profilPic} className="header-pic" />
				<div className="header-info">
					<FullName>{aboutMeData.fullname}</FullName>
					<Profession>{aboutMeData.profession}</Profession>
				</div>
				{isDrawerOpen ? (
					<button className="close-button" onClick={toggleDrawer}>
						<FaTimes fill="var(--color-white)" />
					</button>
				) : (
					<button className="heartbeat-button" onClick={toggleDrawer}>
						<FaChevronDown fill="var(--color-white)" />
					</button>
				)}
			</div>

			<div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
				<AboutIntro>{aboutMeData.aboutme}</AboutIntro>
				<ContactRows />
			</div>
		</MobileHeaderContainer>
	);
};

export default MobileHeader;

const MobileHeaderContainer = styled.div`
	flex-direction: column;
	align-items: center;
	background-color: var(--color-background);
	button {
		background: none;
		border: none;
		cursor: pointer;
	}
	.drawer {
		box-sizing: border-box;
		position: fixed;
		top: ${headerHeight}px;
		left: 0;
		width: 100vw;
		height: 0; // Initially hidden
		background-color: var(--color-background);
		transition: height 300ms ease-in-out;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 2rem;
		overflow: hidden; // Ensure content is hidden when height is 0
		padding: 0 ${padding}rem;
		&.open {
			height: calc(100vh - ${headerHeight}px); // Fill the rest of the screen
		}
	}
	.drawer-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: ${padding}rem;
		box-sizing: border-box;
		width: 100%;
		/* gap: 0.2rem; */
	}
	.header-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1;
	}
	.address {
		display: inline-block;
	}
	.close-button {
		background: none;
		border: none;
		cursor: pointer;
		color: var(--color-white);
	}
	.heartbeat-button {
		animation: slideUpDown 3.5s infinite;
	}

	@keyframes slideUpDown {
		0%,
		100% {
			transform: translateY(-10px);
		}
		50% {
			transform: translateY(10px);
		}
	}
`;
