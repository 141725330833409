import { ProSkillsInterface } from "../Interfaces/ProSkillsInterface";

export const skillsData: ProSkillsInterface[] = [
  {
    name: "HTML, CSS, Javascript",
    percentage: 95,
  },
  {
    name: "Typescript",
    percentage: 95,
  },
  {
    name: "React.js, React Native",
    percentage: 95,
  },
  {
    name: "Angular.js, Vue.js",
    percentage: 85,
  },
  {
    name: "Node.js",
    percentage: 90,
  },
  {
    name: "Ionic, Expo",
    percentage: 85,
  },
  {
    name: "Postgres, MySql",
    percentage: 90,
  },
  {
    name: "Prisma ORM",
    percentage: 90,
  },
  {
    name: "MangoDB",
    percentage: 80,
  },
  {
    name: "Android/IOS",
    percentage: 85,
  },
  {
    name: "Game dev(Unity 3D)",
    percentage: 83,
  },
  {
    name: "C#",
    percentage: 73,
  },
  {
    name: "Flutter",
    percentage: 63,
  },
  {
    name: "Python",
    percentage: 63,
  },
  {
    name: "Docker, Git, Linux",
    percentage: 82,
  },
];
