import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

export const JobBot: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [jobDescription, setJobDescription] = useState<string>("");

  const handleSendClick = async () => {
    try {
      const response = await axios.post(
        "https://hook.eu2.make.com/rix4ebbmg96yxvbbatkyjbbh065092cc",
        {
          email,
          job_description: jobDescription,
        }
      );
      console.log("Response from server:", response.data);
      // Optionally reset the form after successful submission
      setEmail("");
      setJobDescription("");
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <Container>
      <Title>JOB BOT</Title>
      <FormContainer>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email"
        />
        <TextArea
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          placeholder="Enter job description"
          rows={5}
        />
        <SendButton onClick={handleSendClick}>Send</SendButton>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #002147;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SendButton = styled.button`
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #004799;
  }
`;

export default JobBot;
